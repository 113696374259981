
.stepper-alligment{
  display: flex;
  justify-content: left;
}

.hz-progressbar {
    counter-reset: none;
  }
  
  .hz-progressbar li {
    list-style-type: none;
    float: left;
    width: 20%;
    position: relative;
    text-align: center;
  }
  
  
  .hz-progressbar li:after {
    content: '';
    width: 1rem;
    height: 1rem;
    border: 1px solid black;
    display: block;
    text-align: center;
    margin: 0 auto 10px auto;
    border-radius: 50%;
    background-color: black;
    
  }
  
  .hz-progressbar li:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 0.20rem;
    background-color: black;
    bottom: 1rem;
    left: -50%;
    z-index: 1;
  }
  
  .hz-progressbar li:first-child:before {
    content: none;
  }
  
  .hz-progressbar li.active {
    color: blue;
  }
  
  .hz-progressbar li.active:after{
    border-color: blue;
    background-color: blue;
  }
  
  .hz-progressbar li.active + li.active:before {
    background-color: blue;
  }  

 .horizontal-stepper
 {
    background-color: white;
 }

 #text
 {
     fill: #000000; 
     text-anchor:middle;
 }
 #text > .Visited
 {
     font-weight:500;
 }
 #text > .Selected
 {
     font-weight:700;
 }

 #lines
 {
     fill:$neutral-color-medium;
 }
 #lines > .Visited
 {
    fill: $primary-color; 
 }

 #circles
 {
    fill:$neutral-color-medium;
    stroke:transparent;
    stroke-width:0;
 }
 #circles > .Visited
 {
    fill: $primary-color;
    stroke:$primary-color;
    stroke-width:1px; 
 }
 #circles > .Selected
 {
    fill: $primary-color;
    stroke:$primary-color; 
    stroke-width:2px; 
 }

@media (max-width: 600px){

  .stepper-alligment{
    display: flex;
    justify-content: left;
  }
  
  #prog-stepper{
    width: 550px;
  }

  rect{
    width: 6rem;
  }

  .circle-0{
    transform: translateX(-1%);
  }

  .circle-1{
    transform: translateX(-8%);
  }

  .circle-2{
    transform: translateX(-15%);
  }

  .circle-3{
    transform: translateX(-21%);
  }

  .circle-4{
    transform: translateX(-27%);
  }

  .rect-0{
    transform: translateX(-2%);
  }

  .rect-1{
    transform: translateX(-8%);
  }

  .rect-2{
    transform: translateX(-15%);
  }

  .rect-3{
    transform: translateX(-21%);
  }

  .rect-4{
    transform: translateX(-35%);
  }

  #text{
    font-size: 0.75rem;
  }

  .text-0{
    transform: translateX(-1%);
  }

  .text-1{
    transform: translateX(-8%);
  }

  .text-2{
    transform: translateX(-15%);
  }

  .text-3{
    transform: translateX(-20%);
  }

  .text-4{
    transform: translateX(-27%);
  }
}

@media screen and (min-width:200px) and (max-width:450px){

  .stepper-alligment{
    display: flex;
    justify-content: left;
  }

  #prog-stepper{
    width: 260px;
  }

  rect{
    width: 5rem;
  }

  #text{
    font-size: 0.75rem;
  }

  .text-0{
    transform: translateX(-3%);
  }

  .text-1{
    transform: translateX(-16%);
  }

  .text-2{
    transform: translateX(-26%);
  }

  .text-3{
    transform: translateX(-37%);
  }

  .text-4{
    transform: translateX(-50%);
  }

  .circle-0{
    transform: translateX(-3%);
  }

  .circle-1{
    transform: translateX(-16%);
  }

  .circle-2{
    transform: translateX(-26%);
  }

  .circle-3{
    transform: translateX(-37%);
  }

  .circle-4{
    transform: translateX(-50%);
  }

  .rect-0{
    transform: translateX(-3%);
  }

  .rect-1{
    transform: translateX(-15%);
  }

  .rect-2{
    transform: translateX(-25%);
  }

  .rect-3{
    transform: translateX(-37%);
  }

}

@media screen and (min-width:2200px){
  .stepper-alligment{
    display: flex;
    justify-content: left;
  }
  
  #prog-stepper{
    width: 760px;
  }

  rect{
    width: 7rem;
  }

  .circle-0{
    transform: translateX(0%);
  }

  .circle-1{
    transform: translateX(7%);
  }

  .circle-2{
    transform: translateX(14%);
  }

  .circle-3{
    transform: translateX(21%);
  }

  .circle-4{
    transform: translateX(27%);
  }

  .rect-0{
    transform: translateX(0%);
  }

  .rect-1{
    transform: translateX(7%);
  }

  .rect-2{
    transform: translateX(14%);
  }

  .rect-3{
    transform: translateX(21%);
  }

  .rect-4{
    transform: translateX(-1%);
  }

  #text{
    font-size: 0.9rem;
  }

  .text-0{
    transform: translateX(1%);
  }

  .text-1{
    transform: translateX(7%);
  }

  .text-2{
    transform: translateX(14%);
  }

  .text-3{
    transform: translateX(21%);
  }

  .text-4{
    transform: translateX(27%);
  }
}

@media screen and (min-width:2800px){
  .stepper-alligment{
    display: flex;
    justify-content: left;
  }
  
  #prog-stepper{
    width: 950px;
  }

  rect{
    width: 5rem;
  }

  .circle-0{
    transform: translateX(0%);
  }

  .circle-1{
    transform: translateX(7%);
  }

  .circle-2{
    transform: translateX(14%);
  }

  .circle-3{
    transform: translateX(21%);
  }

  .circle-4{
    transform: translateX(27%);
  }

  .rect-0{
    transform: translateX(0%);
  }

  .rect-1{
    transform: translateX(7%);
  }

  .rect-2{
    transform: translateX(14%);
  }

  .rect-3{
    transform: translateX(21%);
  }

  .rect-4{
    transform: translateX(-1%);
  }

  #text{
    font-size: 0.7rem;
  }

  .text-0{
    transform: translateX(1.3%);
  }

  .text-1{
    transform: translateX(7%);
  }

  .text-2{
    transform: translateX(14%);
  }

  .text-3{
    transform: translateX(21%);
  }

  .text-4{
    transform: translateX(27%);
  }
}
