.drag-region{
  border: 2px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
}

.upload-content{
  p{
    color: white;
    background-color: black;
    margin: 0 4px;
    border-radius: 6px;
    height: 24px;
    width: 50px;

    @media screen and (min-width: 2100px) {
      height: 35px;
      width: 80px;
    }

    @media screen and (min-width: 2800px) {
      height: 50px;
      width: 100px;
    }
  }
}

.attachment{
  color: var(--premera-neutral-1, #1F1F1F);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 144.444% */
  display: flex;
  align-items: center;
  padding: 4px;
}

.list-content{
  color: #292931;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 150% */
  margin: 10px 0;

  li{
    @media screen and (min-width:2200px) {
      margin: 8px 0;
    }

    @media screen and (min-width:2800px) {
      margin: 20px 0;
    }
  }

  @media screen and (min-width:1440px){
    text-wrap: nowrap;
  }

  @media screen and (min-width:2800px) {
    margin: 30px 0;
  }
}

.drag-drop-heading{
  color: #313234;
  font-family: Lora;
  font-size: 1.758rem;
  font-style: normal;
  font-weight: 400;
  line-height: 33px; /* 117.296% */
}

.ul-attachments{
  background-color: #FAFAFA;
  list-style: none;
  flex-shrink: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  ul{
    padding-bottom: 0;
    margin-bottom: 0;
    padding-left: 0.8rem;
  }
}

.removeFile{
  color: #117BAB;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-left: 0.5rem;
}

.updateFailed{
  color: #292931;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; 
}

.tooltipForClaims {
  position: relative;
  display: inline-block;
  height: 1.5rem;
  width: 1.5rem;
  margin-left: 0.2rem;
  cursor: pointer;

  @media screen and (min-width:2100px) {
    height: 1.5rem;
    width: 1.5rem;

    img{
      height: 1.5rem;
      width: 1.5rem;
    }
  }
}

.tooltipForClaims .tooltiptextForclaims {
  visibility: hidden;
  width: 15.5rem;
  background-color: black;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 5px 4px;
  position: absolute;
  z-index: 1;
  bottom: 130%;
  margin-left: -136px;
  color: var(--neutral-white, #FFF);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.tooltipForClaims .tooltiptextForclaims::after {
  content: "";
  position: absolute;
  top: 100%;
  //left: 50%;
  margin-left: 16%;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;

  @media screen and (min-width: 2150px) {
    margin-left: 11%;
  }
}

.tooltipForClaims:hover .tooltiptextForclaims {
  visibility: visible;
}

.attachment-info-Label{
  display: flex;
  color: #63666A;
  leading-trim: both;
  text-edge: cap;
  font-size: 1rem;
  font-style: normal;
  margin-top: 0.8rem;
}

.attach-button{
  color: #006CA3;
  border-color: #006CA3;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
